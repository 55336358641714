
import { setISODay } from "date-fns";
import { createContext, useEffect, useReducer, useState } from "react";
import { Navigate } from "react-router-dom";
import { makeRequest } from "../axios";


export const AuthContext = createContext()
export const authReducer = (state, action) => {
    switch (action.type) {
      case 'SIGNUP':
        return {...state, currentUser: action.payload}  
      case 'LOGIN':
        return { ...state, currentUser: action.payload }
      case 'TOKEN':
            return { ...state, token:action.payload }  
     case 'ONCHANGE':
            return { ...state, currentUser: action.payload }
      case 'LOGOUT':
        return { ...state, currentUser: null }
       
      default:
        return state
    }
  }


export const AuthContextProvider =({children})=>{
     
     const [state, dispatch] = useReducer(authReducer, { 
        currentUser:JSON.parse(localStorage.getItem("user")),
        token:localStorage.getItem("accessToken"),
       
       
      })
  
    const [userToken,setUserToken] = useState()
    const [isPending, setIsPending] = useState(false)
    const [errors, setErrors] = useState(null)
    const [loginerror, setLoginError] = useState()
    const [success, setSuccess] = useState(false)
    const [finish,setFinish] = useState(false)
    const [loginsuccess, setLoginSuccess] = useState(false)
    const [notification,_setNotification] = useState('')
    const [enotification, _setEnotification] = useState()
     
    const setNotification = (message)=>{
       _setNotification(message)
       setTimeout(()=>{
         _setNotification('')
       }, 5000)
    }

    const setEnotification = (message)=>{
      _setEnotification(message)
      setTimeout(()=>{
        _setEnotification('')
      }, 5000)
   }
  

  
    const signup = async (inputs)=>{
        try{
            setIsPending(true)
          await makeRequest.post("/createuser", inputs,{
            headers: { "Content-Type": "multipart/form-data" },
          } )
            .then((res)=>{
                   setSuccess(true)
                   setIsPending(false)
                
            })

        }catch(errors){
            setIsPending(true)
             setErrors(errors.response.data.errors)
           setIsPending(false)
        }
    }

    const fgotpw = async (input) =>{
      try{
        setIsPending(true)
        await makeRequest.post("/fgotpassword", input)
        .then(()=>{
          setNotification('Reset password link is sent to your registered emailID, kindly click on the link to reset your password')
          setSuccess(true)
          setIsPending(false)
        })

      }catch(errors){
          setErrors(errors.response.data.errors)
          setIsPending(false)
      }
    }


    const resetpw = async (input) =>{
      try{
        setIsPending(true)
        await makeRequest.post("/resetpw", input)
        .then(()=>{
          setNotification('Your password is reset successfully, kindly login')
          setSuccess(true)
          setIsPending(false)
        })

      }catch(errors){
          setErrors(errors.response.data.errors)
          setIsPending(false)
      }
    }

    const login = async (inputs)=>{
       
        try{
            setIsPending(true)
       await makeRequest.post("/adminlogin",inputs)
       .then((res)=>{
        dispatch({type:'LOGIN', payload:res.data.user})
        dispatch({type:'TOKEN', payload:res.data.accessToken}) 
        localStorage.setItem("accessToken", res.data.accessToken)
        localStorage.setItem("user", JSON.stringify(res.data.user))
         setSuccess(true)
           setIsPending(false)
        
       })
        }catch(errors){
          setIsPending(true)
          setErrors(errors.response.data.errors)
          setLoginError(errors.response.data.message)
          setIsPending(false)
        } 
    }

    

     const logout = async (inputs) =>{
         
        await makeRequest.post("/logout", inputs,{
            headers:{
                "Authorization": "Bearer" + state.token
            }
        })
        .then((res)=>{
           if(res.status == 201){
            dispatch({type:'SIGNUP', payload:null})
            dispatch({type:'TOKEN', payload: null}) 
            localStorage.setItem("accessToken", null)
            localStorage.setItem("user", null)
            window.location.reload()
           }
        })
     }

     const updateprofile = async (inputs)=>{
         
        try{
            setIsPending(true)
            await makeRequest.post("/updateprofile",inputs,{
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res)=>{
                dispatch({type:'ONCHANGE', payload:res.data.user})
                localStorage.setItem("user", JSON.stringify(res.data.user))
                 setSuccess(true)
                 setIsPending(false)
                 setErrors('')
            })
        }catch(res){
            setIsPending(true)
            setErrors(res.data.errors)
            setIsPending(false)
            setSuccess(false)
        } 
       
           
     }

 



    return (
        <AuthContext.Provider value={{ 
          loginsuccess,
          isPending, 
          loginerror,
          finish, 
          errors, 
          ...state,
          dispatch, 
          login ,
           logout,
            userToken,
            updateprofile ,
            success, 
            signup,
            notification,
            setNotification,
            fgotpw,
            resetpw,
            setEnotification,
            enotification
            }}>
          {children}
        </AuthContext.Provider>
      )

}