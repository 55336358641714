import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Matches from '../components/Matches'
import Subscription from '../components/Subscriptions'
import Users from '../components/Users'
import { useAuthContext } from '../hooks/useAuthContext'
import useData from '../hooks/useData'

export default function Dashboard() {
    const {currentUser,token} = useAuthContext()
    const { getdata,ucount, matchdata, getSubs,subdata} = useData()

    useEffect(()=>{
        function go(){
          getdata() 
          getSubs()
        }
        go()
    },[])
   
   
  return (
    <div className="max-w-7xl mx-auto md:ml-52 md:left-52 right-0 min-h-screen flex flex-col items-center px-9  md:px-3 py-6 bg-themel3 overflow-y-scroll ">
                       <h1 className="text-4xl mt-5 font-extrabold text-themed3">Dashboard</h1> 
      
        <div className=' grid  grid-cols-1 md:grid-cols-3 gap-2 md:gap-4 pt-20 items-center justify-between  '>
             {ucount && <Link to="/users"><Users users={ucount}/></Link>}
             {matchdata && <Link to="/matches"><Matches match={matchdata}/></Link>}  
             {subdata && <Link to="/subscriptions"><Subscription subdata={subdata}/></Link>}          
        </div>
        
    </div>
  )
}
