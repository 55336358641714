import { useState } from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import MobileNav from "./components/MobileNav";
import Navbar from "./components/Navbar";
import Users from "./components/Users";
import { useAuthContext } from "./hooks/useAuthContext";
import CreateAdmin from "./pages/CreateAdmin";




import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MatchList from "./pages/MatchList";
import SubList from "./pages/SubList";
import UserList from "./pages/UserList";


function App() {
 const {currentUser,token, notification } = useAuthContext()

  return (
    <div className="">

      <BrowserRouter>
          <MobileNav />
          <Navbar />
          <Routes>
             <Route path="/"  element={!currentUser ? <Home />: <Navigate to="/dashboard" />} />
           <Route path="/createuser" element={currentUser && currentUser.super_admin == 1? <CreateAdmin/>:<Navigate to="/" />} />
             <Route path="/adminlogin" element={!currentUser ? <Login />: <Navigate to="/" />} />
             <Route path="/dashboard" element={currentUser ? <Dashboard /> : <Navigate to="/" />} />
             <Route path="/users" element={currentUser ? <UserList /> : <Navigate to="/" />} />
             <Route path="/matches" element={currentUser ? <MatchList /> : <Navigate to="/" />} />
             <Route path="/subscriptions" element={currentUser ? <SubList /> : <Navigate to="/" />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
