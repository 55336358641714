import { useEffect, useState } from "react"
import { makeRequest } from "../axios"
import logo from "../images/logo512.webp"
import { Photourl } from "../Photourl"
import dummy from "../images/dummy.webp"
import User from "../components/User"

export default function UserList() {
    const [users,setUsers] = useState()
    const [isPending,setIsPending] = useState(false)
    const [id,setId] = useState()
    const {photourl} = Photourl()
    const [isOpen,setIsOpen]= useState(false)


    useEffect(()=>{
        function go(){
            setIsPending(true)
           makeRequest.get("/getusers")
           .then((res)=>{
               setUsers(res.data.users)
               setIsPending(false)
           })
        }
        go()
    },[])

    const handleClick = (id)=>{
        setId(id)
        setIsOpen(true)
    }
   
    const handleClose =()=>{
        setIsOpen(false)
    }
    

    return (
       <> 
     {isPending && (
         <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>        
            <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
            <p className='text-white text-sm font-bold'> Please wait....</p>
        </div>
     )}  
     {users && (
            <div className="max-w-7xl mx-auto md:ml-52 md:left-52 right-0 min-h-screen flex flex-col items-center px-1  md:px-3 py-6 bg-themel3 overflow-y-scroll ">
                     <p className="text-2xl text-themed4 font-extrabold mb-3">Users List</p>
                <div className="  md:p-2  w-10/12 mx-auto">
                     
                     <table className="table-auto w-full ">
                         <thead className="bg-themed3 text-white text-sm h-10 font-medium ">
                            <tr>
                                <th>Sno</th>
                                <th>Pic</th>
                                <th>Family Name</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>City</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Match</th>
                                <th>View</th>
                                <th>Edit</th>
                                
                            </tr>
                         </thead>
                         <tbody>
                            {users && users.map((item,index)=>(
                              <tr key={item.id} className="bg-themel4 border-b border-themed2 text-sm font-bold">  
                                <td>{index+1}</td>
                                {item.profile_pic ?
                                    <td><img src={`${photourl}/avatars/${item.profile_pic}`} alt={item.first_name} className=" w-6 h-6 rounded-full"/></td>
                                    :
                                    <td><img src={dummy} alt={item.first_name} className=" w-6 h-6 rounded-full"/></td>
                                }
                               
                                <td>{item.family_name}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.city}</td>
                                <td>{item.mobile}</td>
                                <td>{item.email}</td>
                                <td>{item.matche && item.matche.first_name}</td>
                                <td><button onClick={()=>{handleClick(item.id)}}  className="m-1 px-2 py-1 rounded-lg bg-themed1 text-white font-thin">View</button></td>
                                <td><button className="m-1 px-3 py-1 rounded-lg bg-orange-400 text-white font-thin">Edit</button></td>
                               
                                
                             </tr>  
                            ))}
                            
                         </tbody>
                     </table>
                     {isOpen && <User id={id} handleClose={handleClose} />}
                </div>
            </div>
     )}
    

     </>
    )
  }
   