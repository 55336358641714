import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeRequest } from '../axios'
import logo from "../images/logo512.webp"
import { Photourl } from '../Photourl'
import { format } from 'date-fns'

export default function Match({id, handleClose}) {
    const [matchSingle,setMatchSingle] = useState()
    const [isPending,setIsPending]= useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const {photourl} = Photourl()

    useEffect(()=>{
        async function go(){
            setIsPending(true)
          await makeRequest.get(`/getmatch/${id}`)
          .then((res)=>{
              setMatchSingle(res.data.match)
              setIsPending(false)
          })
        }
        go()
    },[])

       const handleClick = async ()=>{
          setIsLoading(true)
          await makeRequest.post(`/approve/${id}`)
          .then(()=>{
            setIsLoading(false)
            window.location.reload()
          })
       }

       const disApprove = async ()=>{
        setIsLoading(true)
        await makeRequest.post(`/disapprove/${id}`)
        .then(()=>{
          setIsLoading(false)
          window.location.reload()
        })
     }


  
        return (
            <>
             {!isPending && (
               <div>
                 {matchSingle && (
                <div className="fixed top-0 right-0 bottom-0 left-0 bg-themed3 opacity-90  flex flex-col items-center overflow-scroll z-10">
                    <div className=" max-w-2xl bg-white p-5 mt-3 md:mt-10 opacity-100 rounded-lg shadow-xl z-50">
                         <div className="w-full text-end">
                            <button onClick={handleClose}  className="text-sm font-bold text-black ">close</button>
                         </div>
                      <div className=" min-h-screen max-w-5xl mt-14 mx-auto p-4 flex flex-col items-center space-y-5">
 
                            
                        {matchSingle && 
                                <>
                                  <h1 className="text-2xl font-bold text-themed3 ">{matchSingle.family_name}{" "}{matchSingle.first_name}{" "}{matchSingle.last_name}</h1>
                                 <img src={`${photourl}/bust/${matchSingle.bust_image}`} alt="" className=" " />
                                 <img src={`${photourl}/full/${matchSingle.full_image}`} alt="" className="" />
                                 <img src={`${photourl}/family/${matchSingle.family_image}`} alt="" className=" " />
                                 <div className='flex flex-col items-center justify-center space-y-1'>
                                    
                                     <p className='text-sm font-bold text-themed4'>Date of birth</p>
                                     <p className='text-xl  font-light text-themed2'>{format(new Date(matchSingle.date_of_birth), "dd-MM-yyyy")}</p>
                                     <p className='text-sm font-bold text-themed4'>Educational Qualifications</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.eduqual}</p>
                                     <p className='text-sm font-bold text-themed4'>Working as</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.cadre}</p>
                                     <p className='text-sm font-bold text-themed4'>Nature of job</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.desgn}</p>
                                     <p className='text-sm font-bold text-themed4'>Organization Type</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.occu}</p>
                                     <p className='text-sm font-bold text-themed4'>Place of Work</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.place_of_work}</p>
                                     <p className='text-sm font-bold text-themed4'>About <i className='font-bold text-xl'>{matchSingle.first_name}</i></p>
                                     <p className='text-lg font-light text-themed1'>{matchSingle.about}</p>
                                     <p className='text-sm font-bold text-themed4'>Father's Name</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.fname}</p>
                                     <p className='text-sm font-bold text-themed4'>Mother's Name</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.mname}</p>
                                     <p className='text-sm font-bold text-themed4'>Father's Occupation</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.foccu}</p>
                                     <p className='text-sm font-bold text-themed4'>Mother's Occupation</p>
                                     <p className='text-xl  font-light text-themed2'>{matchSingle.moccu}</p>
                                     <p className='text-sm font-bold text-themed4'>About <i className='font-bold text-xl'>Family</i></p>
                                     <p className='text-lg font-light text-themed1'>{matchSingle.about_family}</p>

                                     
                                 </div>
                                 {!isLoading && !matchSingle.isApproved && <button onClick={handleClick}  className="m-1 px-2 py-1 rounded-lg bg-green-600 text-white font-thin">Approve</button>}
                                 {isLoading && <button  className="m-1 px-2 py-1 rounded-lg bg-green-600 text-white font-thin">approving ....</button>}
                                 {!isLoading && matchSingle.isApproved && <button onClick={disApprove}  className="m-1 px-2 py-1 rounded-lg bg-red-600 text-white font-thin">Disapprove</button>}
                                 {isLoading &&  matchSingle.isApproved && <button  className="m-1 px-2 py-1 rounded-lg bg-red-600 text-white font-thin">disapproving ....</button>}
                                 </>
                          }
                         </div>  
                      </div>
                    </div>
                 )}
             
               </div>
            )}
           {isPending && (
               <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                    <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                    <p className='text-white text-sm font-bold'> Please wait....</p>
             </div> 
           )}

            </>
          )
  

 
}
