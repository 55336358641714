import { Link } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo512.webp"
import dummy from "../images/dummy.webp"
import { Photourl } from "../Photourl"

export default function Navbar() {
    const {currentUser,token, logout} = useAuthContext()
    const {photourl} = Photourl()

        const inputs ={
            token:token
          }
   

    const handleClick = (e)=>{
        e.preventDefault()
          logout(inputs)
         
       }

  return (
    <div className="fixed left-0 w-52 min-h-screen bg-themed3 hidden md:block overflow-y-scroll">
       <div className="w-full flex flex-col items-center mt-10 ">
       <Link to="/"><img src={logo} alt="" className=" w-24 h-12 cursor-pointer"/></Link>
       </div>
       <div className="w-full flex flex-col items-center  ">
       <h1 className=" text-2xl font-bold text-white shadow-md leading-6">CSIMarital</h1>
               <hr className="text-lg font-bold text-white "/>
               <p className="text-sm font-bold text-white">Admin Panel</p>
       </div>
       <div className=" mt-10 flex flex-col items-center space-y-3">
        {currentUser?
           <>
               {currentUser.profile_pic && <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-6 h-6 rounded-full" />}
               {!currentUser.profile_pic && <img src={dummy} alt="" className=" w-6 h-6 rounded-full" />}
             <Link><p className="text-lg font-thin text-themel4">{currentUser.first_name} {currentUser.last_name}</p></Link>
             {currentUser.super_admin == 1 && <Link to="/createuser"><p className="text-lg font-medium text-themel4 ">Create User</p></Link>}
             <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Logout</button>
             <Link to="/dashboard"><p className="text-sm font-medium text-themel4 ">Dashboard</p></Link>
           <Link to="/users"><p className="text-sm font-medium text-themel4 ">Users</p></Link>
           <Link to="/matches"><p className="text-sm font-medium text-themel4 ">Matches</p></Link>
           <Link to="/subscriptions"><p className="text-sm font-medium text-themel4 ">Subscriptions</p></Link>
           <Link><p className="text-sm font-medium text-themel4 ">Communications</p></Link>
             </>
             :
             <>
                <Link to="/adminlogin"><p className="text-lg font-medium text-themel4 ">Login</p></Link>
             </>  
        }
      
          
           
          
       </div>
    </div>
  )
}
