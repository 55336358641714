import { useEffect, useState } from "react"
import { makeRequest } from "../axios"
import logo from "../images/logo512.webp"


export default function SubList() {
    const [subdata,setSubdata] = useState()
    const [isPending,setIsPending] = useState(false)
   

    useEffect(()=>{
        function go(){
            setIsPending(true)
           makeRequest.get("/subdata")
           .then((res)=>{
            
               setSubdata(res.data.subdata)
               setIsPending(false)
           })
        }
        go()
    },[])


   
    return (
       <> 
     {isPending && (
         <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>        
            <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
            <p className='text-white text-sm font-bold'> Please wait....</p>
        </div>
     )}  
     {subdata && (
             <div className="max-w-7xl mx-auto md:ml-52 md:left-52 right-0 min-h-screen flex flex-col items-center px-1  md:px-3 py-6 bg-themel3 overflow-y-scroll ">
                     <p className="text-2xl text-themed4 font-extrabold mb-3">Subscriptions</p>
                <div className="p-2 w-full  md:w-10/12 mx-auto">
                     
                     <table className="table-auto w-full ">
                         <thead className="bg-themed3 text-white text-sm h-10 font-medium ">
                            <tr>
                                <th>Sno</th>
                                <th>Name</th>
                                <th>Order_id</th>
                                <th>Payment_id</th>
                                <th>Amount</th>
                                <th>Date of Payment</th>  
                                <th>Expiring on</th>  
                            </tr>
                         </thead>
                         <tbody>
                            {subdata.data && subdata.data.map((item,index)=>(
                              <tr key={item.id} className="bg-themel4 border-b border-themed2 text-sm font-bold">  
                               <td>{index+1}</td>
                                <td>{item.user.family_name} {item.user.first_name} {item.user.last_name}</td>
                                <td>{item.order_id}</td>
                                <td>{item.payment_id}</td>
                                <td>{item.amount}</td>
                               <td>{item.paid_on}</td>
                               <td>{item.expiry}</td>
                               
                             </tr>  
                            ))}
                            
                         </tbody>
                     </table>
                   
                </div>
            </div>
     )}
    

     </>
    )
  }
   