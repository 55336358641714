import bg from "../images/users.jpg"

export default function Subscription({subdata}) {
  return (
    <div className=' w-80 h-72 flex flex-col items-center space-y-2 mr-2 rounded-2xl border shadow-2xl border-themed3' style={{"backgroundImage":"url('https://api.csimarital.in/storage/images/bgimage.jpg')", "backgroundRepeat":"none", "backgroundSize":"cover"}}>
        <h1 className="text-2xl mt-2 font-extrabold text-themed3">Subscriptions</h1> 
        <h1 className=" text-9xl font-extrabold text-orange-500">{subdata.subnumber}</h1>

        <h1 className="text-2xl mt-2 font-extrabold text-themed3">Total Amount</h1> 
        <h1 className=" text-5xl font-extrabold text-orange-500">{subdata.subamount}</h1>
        
         
    </div>
  )
}
